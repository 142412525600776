<template>
  <main class="navbar-offset-tablet salon-info-background">
    <PageNavigator :page-title="$t('salon-info.breadcrumbs-list-label')" :has-back="true" :button-back-link="'/salon'">
      <template v-slot:action>
        <div>
          <b-button
            v-if="salonId"
            :variant="salonPayble ? 'secondary-light' : 'primary'"
            class="px-4 py-1 mr-1 font-weight-medium poppins-font"
            @click.prevent="changePaybleStatus()"
          >
            <span v-if="salonPayble">Disable online payment</span>
            <span v-else>Activate online payment</span>
          </b-button>
          <b-button variant="primary" class="px-4 py-1 font-weight-medium poppins-font" @click.prevent="submitForm()">
            {{ $t('common.submit-btn') }}
          </b-button>
        </div>
      </template>
    </PageNavigator>

    <div v-if="!isSalonDataSending" class="px-1-5 pt-7-5 salon-flow salon-info">
      <div class="d-flex flex-wrap h-100 align-content-stretch mb-1-5">
        <div class="col-md-6 pl-0 pr-lg-2-5 pr-1 pt-0">
          <!-- FORM INPUTS -->
          <div ref="card"></div>
          <div v-for="(field, index) in formFields" :key="`${index}-field`">
            <label :for="field.name" class="poppins-font text-black mb-0-5 font-weight-medium text-small">
              {{ $t(field.i18KeyLabel) }}
            </label>

            <div class="input-group mb-1-5">
              <input
                v-if="(field.tag === 'input' && field.type === 'text') || field.type === 'email'"
                :id="field.name"
                v-model="field.value"
                v-validate="field.validateRules"
                v-mask="field.name === 'phone_number' || field.name === 'phone_number_second' ? '##########' : nomask"
                :data-vv-as="$t(field.i18KeyLabel)"
                :name="field.name"
                :type="field.tyle"
                :placeholder="$t(field.i18KeyPlaceholder)"
                class="form-control w-100 px-1-5"
                autocomplete="new-password"
              />

              <input
                v-if="field.tag === 'input' && field.type === 'number'"
                :id="field.name"
                v-model="field.value"
                v-validate="field.validateRules"
                :data-vv-as="$t(field.i18KeyLabel)"
                :name="field.name"
                :type="field.tyle"
                :min="field.min"
                :max="field.max"
                :step="field.step"
                :placeholder="$t(field.i18KeyPlaceholder)"
                class="form-control w-100 px-1-5"
                autocomplete="new-password"
              />

              <textarea
                v-if="field.tag === 'textarea'"
                :id="field.name"
                v-model="field.value"
                v-validate="field.validateRules"
                :data-vv-as="$t(field.i18KeyLabel)"
                :name="field.name"
                :type="field.tyle"
                :placeholder="$t(field.i18KeyPlaceholder)"
                class="w-100 border rounded form-control"
                rows="7"
                autocomplete="new-password"
              ></textarea>

              <span v-if="errors.has(field.name)" class="error-message mt-1">{{ errors.first(field.name) }}</span>
            </div>
          </div>

          <!-- FORM INPUTS -->
        </div>

        <div class="col-md-6 pr-0 pl-lg-2-5 pl-1 pt-0 mb-1-5 overflow-hidden h-auto">
          <div>
            <label for="address" class="poppins-font text-black mb-0-5 font-weight-medium text-small">
              {{ $t('salon-info.form-label.address') }}
            </label>
            <div class="input-group mb-1-5">
              <gmap-autocomplete
                ref="autocomplete"
                :value="autocompleteValue"
                class="form-control w-100 px-1-5 border-right-0"
                placeholder
                :options="options"
                @place_changed="setPlace"
              ></gmap-autocomplete>

              <div class="input-group-append cursor-pointer">
                <span class="input-group-text text-gray bg-white px-1-5 py-0-8 border-left-0">
                  <font-awesome-icon :icon="['far', 'search']"></font-awesome-icon>
                </span>
              </div>

              <span v-if="errors.has('address')" class="error-message mt-1 col-12 pl-0">{{
                errors.first('address')
              }}</span>
            </div>
          </div>

          <div class="h-100 w-100">
            <GmapMap ref="googleMap" :center="choosenPlace" :zoom="mapZoom" class="h-100" :options="options">
              <GmapMarker v-if="marker" :position="marker" />
            </GmapMap>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { mapValues, keyBy } from 'lodash'
import PageNavigator from '@/components/PageNavigator'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import { eventHub } from '@/_utils/eventhub'

export default {
  name: 'SalonInfo',
  directives: { mask },
  components: {
    PageNavigator
  },
  data() {
    return {
      accountCreationErrors: [],
      isSalonDataSending: false,
      nomask: {
        mask: '*'.repeat(255),
        tokens: {
          '*': { pattern: /./ }
        }
      },
      formFields: [
        {
          i18KeyLabel: 'salon-info.form-label.title',
          type: 'text',
          tag: 'input',
          name: 'title',
          value: '',
          validateRules: 'required|min:2|max:40',
          i18KeyPlaceholder: ''
        },
        {
          i18KeyLabel: 'salon-info.form-label.phone',
          type: 'text',
          tag: 'input',
          name: 'phone_number',
          value: '',
          validateRules: 'required|max:13',
          i18KeyPlaceholder: ''
        },
        {
          i18KeyLabel: 'salon-info.form-label.addition_phone',
          type: 'text',
          tag: 'input',
          name: 'phone_number_second',
          value: '',
          validateRules: 'max:13',
          i18KeyPlaceholder: ''
        },
        {
          i18KeyLabel: 'salon-info.form-label.business_email',
          type: 'email',
          tag: 'input',
          name: 'business_email',
          value: '',
          validateRules: 'required|email',
          i18KeyPlaceholder: ''
        },
        {
          i18KeyLabel: 'salon-info.form-label.private_email',
          type: 'email',
          tag: 'input',
          name: 'private_email',
          value: '',
          validateRules: 'required|email',
          i18KeyPlaceholder: ''
        },
        {
          i18KeyLabel: 'salon-info.form-label.description',
          type: 'text',
          tag: 'textarea',
          name: 'description',
          value: '',
          validateRules: 'required|max:9999',
          i18KeyPlaceholder: 'salon-info.form-label.description_placeholder'
        }
      ],
      salonId: null,
      mapPayload: {
        full_address: '',
        latitude: '',
        longitude: '',
        city: ''
      },
      autocompleteValue: '',
      choosenPlace: {
        lat: 46.948,
        lng: 7.4474
      },
      marker: null,
      mapZoom: 18,
      options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
        fields: ['geometry', 'formatted_address', 'name', 'address_components'],
        componentRestrictions: {
          country: 'ch'
        },
        styles: [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          }
        ]
      },
      createSuccessMessage: {
        type: 'Success!',
        messageI18Key: 'common.alerts.actions.created'
      },
      updateSuccessMessage: {
        type: 'Success!',
        messageI18Key: 'common.alerts.actions.updated'
      }
    }
  },
  watch: {
    choosenPlace: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          let location = newVal.geometry.location
          let lat = typeof location.lat === 'function' ? location.lat() : location.lat
          let lng = typeof location.lng === 'function' ? location.lng() : location.lng

          this.setMapData(newVal.formatted_address, +lat, +lng)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      userRole: 'user/getRole'
    }),
    ...mapState('salons', ['salonPayble']),
    dayList() {
      let dayList = []
      let dateGoupFields = this.paymentFormFields[3]
      let selectedYear = dateGoupFields.children.find(field => field.name === 'dobYear')
      let selectedMonth = dateGoupFields.children.find(field => field.name === 'dobMonth')

      if (Boolean(selectedYear.value) && Boolean(selectedMonth.value)) {
        let countDay = moment(`${selectedYear.value}-${selectedMonth.value}`, 'YYYY-MM').daysInMonth()
        dayList = Array.apply(null, Array(countDay)).map((it, index) => index + 1)
      }

      return dayList
    }
  },
  mounted() {
    if (!this.choosenPlace) this.mapPanTo()
    if (this.accountInfo.salon_id !== undefined && this.accountInfo.salon_id !== null) {
      this.fetchInfo(this.accountInfo.salon_id).then(response => {
        this.setFormData(response)
      })
    }
  },
  beforeDestroy() {
    this.removeLeaveProcessEventListener()
  },
  beforeRouteLeave(to, from, next) {
    if (this.isSalonDataSending) {
      this.leaveConfirmMessage()
    } else {
      next()
    }
  },
  methods: {
    ...mapActions({
      fetchInfo: 'salons/getInfo',
      createSalon: 'salons/createSalon',
      updateSalon: 'salons/updateSalon',
      getAccountInfo: 'user/getAccountInfo',
      changePayble: 'salons/changePayble',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    changePaybleStatus() {
      const payload = {
        salon_id: this.salonId,
        payable: this.salonPayble ? 0 : 1
      }

      this.changePayble(payload)
    },
    ...mapMutations({
      setSalonPaybleStore: 'salons/SET_SALON_PAYBLE'
    }),
    addLeaveProcessEventListener() {
      window.addEventListener('beforeunload', this.leaveProcess)
    },
    removeLeaveProcessEventListener() {
      window.removeEventListener('beforeunload', this.leaveProcess)
    },
    selectPlaceholder(field) {
      return field.name === 'dobDay'
        ? this.$t('salon-payment.date')
        : field.name === 'dobMonth'
        ? this.$t('salon-payment.month')
        : field.name === 'dobYear'
        ? this.$t('salon-payment.year')
        : this.$t('salon-payment.date')
    },
    toolTipTemplate() {
      const { title, conditions_1, conditions_2, conditions_3, conditions_4, conditions_5 } = this.$t(
        'salon-info.billing-text.tooltip'
      )
      return `<div class="documents-list">
            ${title}
            <ul style="width: 100%;">
              <li>${conditions_1}</li>
              <li>${conditions_2}</li>
              <li>${conditions_3}</li>
              <li>${conditions_4}</li>
              <li>${conditions_5}</li>
            </ul>
        </div>`
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        this.addressValidate()

        let payload = {
          ...mapValues(keyBy(this.formFields, 'name'), 'value'),
          ...this.mapPayload
        }

        if (this.userRole === 'owner') {
          payload = {
            ...payload,
            ...{ owner_id: this.accountInfo.id }
          }
        }

        payload = {
          ...payload,
          ...{ phone_number: payload.phone_number.substring(1) },
          ...{ phone_number_second: payload.phone_number_second.substring(1) }
        }

        if (result) {
          if (!this.salonId) {
            eventHub.$emit('before-request')
            this.isSalonDataSending = true
            this.addLeaveProcessEventListener()

            eventHub.$emit('before-request')

            this.createSalon({ ...payload })
              .then(() => {
                eventHub.$emit('before-request')

                this.getAccountInfo()
                  .then(() => {
                    eventHub.$emit('after-response')
                    this.isSalonDataSending = false
                    this.removeLeaveProcessEventListener()

                    this.setSuccess(this.createSuccessMessage)

                    this.$router.push('/salon')
                  })
                  .catch(() => {
                    eventHub.$emit('request-error')
                    this.removeLeaveProcessEventListener()
                    this.isSalonDataSending = false

                    this.setError({
                      type: 'create_salon',
                      statusCode: 0,
                      messageI18Key: 'common.alerts.actions.global_error'
                    })
                  })
              })
              .catch(() => {
                eventHub.$emit('request-error')
                this.removeLeaveProcessEventListener()
                this.isSalonDataSending = false

                this.setError({
                  type: 'create_salon',
                  statusCode: 0,
                  messageI18Key: 'common.alerts.actions.global_error'
                })
              })
          } else {
            this.updateSalon({ ...payload, salon_id: this.salonId })
              .then(() => {
                this.setSuccess(this.updateSuccessMessage)
                this.isSalonDataSending = false
                this.$router.push('/salon')
              })
              .catch(errors => {})
          }
        }
      })
    },
    setPlace(place) {
      this.choosenPlace = place
    },
    mapPanTo(lat = 46.948, lng = 7.4474) {
      this.$refs.googleMap.$mapPromise.then(map => {
        map.panTo({ lat: lat, lng: lng })
      })
    },
    setMarker(lat, lng) {
      this.marker = { lat, lng }
    },
    setMapData(fullAddress, lat, lng) {
      this.mapPanTo(lat, lng)
      this.setMarker(lat, lng)
      this.setMapPayload(fullAddress, lat, lng)
      this.autocompleteValue = fullAddress
    },
    setMapPayload(fullAddress, lat, lng) {
      this.mapPayload['full_address'] = fullAddress
      this.mapPayload['latitude'] = lat
      this.mapPayload['longitude'] = lng

      this.$refs.googleMap.$mapPromise.then(map => {
        map.panTo({ lat: lat, lng: lng })

        const geocoder = new google.maps.Geocoder()

        geocoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
          if (status === 'OK') {
            this.mapPayload['city'] = results[0].address_components[2].long_name
          }
        })
      })
    },
    addressValidate() {
      if (
        (this.choosenPlace.formatted_address === undefined && !this.autocompleteValue) ||
        (this.autocompleteValue && !this.autocompleteValue.length)
      ) {
        this.$validator.errors.add({
          field: 'address',
          msg: 'The address field is required'
        })
      } else {
        this.$validator.errors.remove('address')
      }
    },
    setFormData(salon) {
      for (let [key, value] of Object.entries(salon)) {
        this.formFields.find(field => {
          if (field.name === key) field.value = value
        })
      }

      this.salonId = salon.salon_id
      this.setSalonPaybleStore(salon.payable)

      this.setMapData(
        salon.address_full_address,
        parseFloat(salon.address_latitude),
        parseFloat(salon.address_longitude)
      )
    },
    leaveConfirmMessage() {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h3 text-center mb-0 text-black pt-4 pb-1-5'] }, [
          this.$t('salon-info.confirm.leave_on_saved_form_title')
        ]),
        h('p', { class: ['text-center pb-0 mb-0 lh-1-5'] }, [this.$t('salon-info.confirm.leave_on_saved_form_title')])
      ])

      return this.$bvModal
        .msgBoxOk([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-4 px-4',
          okOnly: true
        })
        .then(value => {
          return value
        })
        .catch(error => {
          return error
        })
    },
    leaveProcess(e) {
      e.preventDefault()
      e.returnValue = ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
